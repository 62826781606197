<template>
  <div class="login">
    <div class="login__form">
      <div class="login__form__container">
        <div class="login__form__container__logo">
          <ui-logo :logo="darkTheme ? 'logo-dark.svg' : 'logo-light.svg'" width="250px" />
        </div>
        <router-view></router-view>
      </div>
    </div>
    <div class="login__cover"></div>
  </div>
</template>

<script>
import UiLogo from '@/components/UI/Logo.vue'
import { mapState } from 'vuex'

export default {
  name: 'Login',
  components: {
    UiLogo,
  },
  created() {
    this.$i18n.locale = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2)
  },
  computed: {
    ...mapState({
      darkTheme: state => state.backoffice.darkTheme,
    }),
  },
}
</script>

<style lang="scss">
.login {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  background-color: $generic-color-bg;

  &__form {
    display: flex;
    justify-content: center;
    z-index: 1;
    margin: auto;
    background-color: $generic-color-bg;
    width: 100%;
    overflow-y: auto;

    @media (min-width: $screen-sm) {
      border-radius: $radius-default;
      box-shadow: 0 0 15px var(--box-shadow-color);
      width: initial;
      min-width: 80%;
      height: 80%;
    }

    @media (min-width: $screen-md) {
      min-width: 60%;
      height: 75%;
    }

    @media (min-width: $screen-xl) {
      min-width: 50%;
      height: 85%;
    }

    &__container {
      position: relative;
      margin: auto;
      padding: $gutter-mobile;
      width: 100%;

      @media (min-width: $screen-xs) {
        padding: $gutter-mobile;
        max-width: calc(380px + #{$gutter-mobile} * 2);
      }

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        max-width: calc(380px + #{$gutter-tablet} * 2);
      }

      @media (min-width: $screen-xl) {
        padding: $gutter-desktop;
        max-width: calc(400px + #{$gutter-desktop} * 2);
      }

      &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: $gutter-tablet;

        @media (min-width: $screen-xl) {
          margin-bottom: $gutter-desktop;
        }
      }
    }
  }

  &__cover {
    display: none;

    @media (min-width: $screen-sm) {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #0c0058;
    }
  }
}
</style>
